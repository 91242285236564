
#configWrapper {
  max-width: 768px;
  color:rgba(245, 245, 245, 0.692);
}
.box {
  border: 1px rgba(245, 245, 245, 0.609) solid;
  border-radius: 5px;
}
.box h3{
  border-bottom: 1px rgba(245, 245, 245, 0.609) solid;
  padding:10px;
  font-size: large;
}
.configTitle{
  text-align: right;
}
.configTitle h5{
  color:white;
  font-size: 1.1rem;
  margin: 8px 0px 0px 0px;
  transition: ease-in all 0.2s;
}

.right{
  text-align: right;
}
.center{
  text-align: center;
}
.bold {
  font-weight: bold;
}

.infoRow hr{
  margin:5px;
}
.infoRow h5{
  display: inline-block;
}
.spinner{
  width:90px;
  display: inline-block;
}
.infoPic{
  width:100%;
  max-width: 250px;
}

#verticalRange {
  width:132px;
  transform: rotate(90deg);
  transform-origin: left;
  margin-left: 20px;
}
#lengthRange {
  max-width:300px;
  width:100%;
  margin-left: 45px;
}
#diamRange {
  width:132px;
}
#verticalCol {
  height:132px;
  margin-right: -105px;
}
#heaterIllustration{
  width:100%;
  max-width:300px;
  max-height:132px;
  margin-top:10px;
}
#diamIllustration{
  max-width:132px;
  height:132px;
  margin-top:10px;
}
#dimDiv 
{
    background-repeat:repeat;
    border: 1px solid #c62f24;
    float: left;
}
#diamDiv 
{
    background-repeat:repeat;
    border: 1px solid #c62f24;
    float: left;
    border-radius: 50%;
}

.hidden {
  max-height: 0;
  overflow: hidden;
  display: block;
  opacity: 0;
  transition: all 1s ease;
}

.hidden.show {
  max-height: inherit; /* Set this to an appropriate value */
  opacity: 1;
}
.form-select {
  width:auto;
}

@media (max-width: 575px) {
  .configTitle h5{
    font-size: 0.88rem;
    margin: 10px 0px 0px 0px;
  }
}
